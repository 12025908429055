$(function(){
	/** @var {object} リクエスト(ajax) */
	var search_req;

	// かんたん見積り--------------------------------------	
	// 品番生成用プロパティ
	var rackData;

	searchInit();
	$(window).on('pageshow', searchInit);

	// 初期化関数
	function searchInit(){
		console.log(sessionStorage.getItem('search_rack_data'));

		if (sessionStorage.getItem('search_rack_data')) {
			// 前回検索時のデータがあればそれを展開
			rackData = JSON.parse(sessionStorage.getItem('search_rack_data'));

			// ボタンselected
			if (rackData.model) {
				var btn_model = $('#model').find('button[data-code=' + rackData.model + ']');
				if (btn_model.length) {
					btn_model.trigger('click');
				}

				$('#sp-filter-model option').attr('selected', false);
				$('#sp-filter-model option[data-code=' + rackData.model + ']').attr('selected', 'selected');
			}

			if (rackData.height) {
				var btn_height = $('#search-height').find('button[data-code=' + rackData.height + ']');
				if (btn_height.length) {
					btn_height.trigger('click');
				}

				$('#sp-filter-height option').attr('selected', false);
				$('#sp-filter-height option[data-code=' + rackData.height + ']').attr('selected', 'selected');
			}
			
			if (rackData.width) {
				var btn_width = $('#search-width').find('button[data-code=' + rackData.width + ']');
				if (btn_width.length) {
					btn_width.trigger('click');
				}

				$('#sp-filter-width option').attr('selected', false);
				$('#sp-filter-width option[data-code=' + rackData.width + ']').attr('selected', 'selected');
			}

			if (rackData.depth) {
				var btn_depth = $('#search-depth').find('button[data-code=' + rackData.depth + ']');
				if (btn_depth.length) {
					btn_depth.trigger('click');
				}

				$('#sp-filter-depth option').attr('selected', false);
				$('#sp-filter-depth option[data-code=' + rackData.depth + ']').attr('selected', 'selected');
			}

			if (rackData.row) {
				var row = Number(rackData.row);
				$('#search_step option').attr('selected', false);
				$('#search_step option[value=' + row + ']').attr('selected', 'selected');	

				$('#sp_search_step option').attr('selected', false);
				$('#sp_search_step option[value=' + row + ']').attr('selected', 'selected');
			}

			if (rackData.type) {
				var btn_type = $('#search-type').find('button[data-code=' + rackData.type + ']');
				if (btn_type.length) {
					btn_type.trigger('click');
				}

				$('#sp-filter-type option').attr('selected', false);
				$('#sp-filter-type option[data-code=' + rackData.type + ']').attr('selected', 'selected');
			}
		} else {
			rackData = {
				maker: "",
				model: "",
				height: "",
				width: "",
				depth: "",
				row: "",
				type: "",
				color: "W"
			};
		}
	}

	// ボタンクリック
	$('.rack-search .btn').on('click', function(){

		// current制御
		$(this).parent().find('.btn').removeClass('current');
		$(this).addClass('current');

		var param = $(this).data('param');
		$('#'+param).val($(this).attr('name'));

		// 耐荷重ボタンが押された場合のボタン制御
		if(param == 'm') {
			var model = $(this).attr('name');

			// H
			$('.btn[name="h1"]').attr('disabled', false);
			$('.btn[name="h7"]').attr('disabled', true);
			
			if(model.match(/200/)) {
				$('.btn[name="h7"]').attr('disabled', false);
			} else if(model.match(/150/)) {
				$('.btn[name="h1"]').attr('disabled', true);
			}

			// WD
			$('.btn[name="w1"]').text('900mm');
			if(model.match(/120/)) {
				$('.btn[name="w1"]').text('875mm');
			}
			
			if(model.match(/(300|500)/)) {
				$('.btn[data-param="w"]').each(function(){
					var txt = $(this).html();
					$(this).html(txt.replace('00mm', '50mm'));
				});
				$('.btn[name="d1"]').attr('disabled', true);
				$('.btn[name="d4"]').attr('disabled', false);
				$('.btn[name="d5"]').attr('disabled', false);
			} else {
				$('.btn[data-param="w"]').each(function(){
					var txt = $(this).html();
					$(this).html(txt.replace('50mm', '00mm'));
				});
				$('.btn[name="d1"]').attr('disabled', false);
				$('.btn[name="d4"]').attr('disabled', true);
				$('.btn[name="d5"]').attr('disabled', true);
			}

			// Type
			if(model.match(/(200|300|500)/)) {
				$('.btn[name="a"]').attr('disabled', false);
			} else {
				$('.btn[name="a"]').attr('disabled', true);
			}

			// currentボタンにdisabledが振られた場合
			$('.current').each(function(){
				if($(this).attr('disabled')) {
					$(this).removeClass('current');
					$('#'+$(this).data('param')).val('');

					if($(this).data('param') == 'h') {
						rackData.height = "";
					} else if($(this).data('param') == 'd') {
						rackData.depth = "";
					} else if($(this).data('param') == 't') {
						$('#type-s').addClass('current');
						$('#t').val('s');
						rackData.type = "S";
					} 
				}
			});
		}

		// 品番パラメータ更新
		var code = $(this).data('code');
		switch(param) {
			case 'm':
				rackData.model = code;
				if(code.match(/[BMH]/)) {
					rackData.maker = 'YS';
				} else {
					rackData.maker = 'SO';
				}
				break;
			case 'h': rackData.height = code; break;
			case 'w': rackData.width = code; break;
			case 'd': rackData.depth = code; break;
			case 't': rackData.type = code; break;
		}

        console.log(param);

		// 段数選択の制御
		rowControl();

		// 品番取得、ラック検索、納期取得、寸法図プレビュー更新までの一括処理
		search();
	});


	// 段数selectチェンジ
    $('#search_step').on('change', function(){
		changeRow($(this));
    });

	// SP 段数selectチェンジ
	$('#sp_search_step').on('change', function(){
		changeRow($(this));
	});

	// 段数select変更時の処理
	function changeRow($elem) {
		$('#'+$elem.attr('name')).val($elem.val());
		// 品番パラメータ更新
		rackData.row = '0'+$elem.val();
		rackCodeGenerate(rackData);

		// 品番取得、ラック検索、納期取得、寸法図プレビュー更新までの一括処理
		search();

		var step = $elem.val();
		if(!step) {
			step = 0;
		}
	}
    
	// 台数selectチェンジ
	$('#quantity').on('change', function(){
		$('#'+$(this).attr('name')).val($(this).val());
		
		// 品番取得
		var rackCode = rackCodeGenerate(rackData);
		
		// 品番確定判定
		if(rackCode) {
			// 在庫・出荷日・到着日取得
			getStock(rackCode, $(this).val(), $('#pref-list').val());
		}
	});
	
	// 納期表示用の都道府県selectチェンジ
	$('#pref-list').on('change', function(){
		// 品番取得
		var rackCode = rackCodeGenerate(rackData);
		
		// 品番確定判定
		if(rackCode) {
			// 在庫・出荷日・到着日取得
			getStock(rackCode, $('#quantity').val(), $(this).val());
		}
	});

	// sp簡単検索
	$('.js-search-sp').on('change', function(){
		var spId = $(this).attr('id');
		var spValue = $(this).val();
	  
		if(spId == 'sp-filter-model') {
		  $('#rack-search-table-head #model .btn[value="'+spValue+'"]').trigger('click');
		}else if(spId == 'sp-filter-height') {
		  $('#rack-search-table-head #search-height .btn[value="'+spValue+'"]').trigger('click');
		}else if(spId == 'sp-filter-width') {
		  $('#rack-search-table-head #search-width .btn[value="'+spValue+'"]').trigger('click');
		}else if(spId == 'sp-filter-depth') {
		  $('#rack-search-table-head #search-depth .btn[value="'+spValue+'"]').trigger('click');
		}else if(spId == 'sp-filter-type') {
		  $('#rack-search-table-head #search-type .btn[value="'+spValue+'"]').trigger('click');
		}
		spSizeFilterUpdate();
		spSelectedControlAll();
	});

	// パラメーターを品番に変換する関数
	function rackCodeGenerate(rackData) {
		var rackCode = rackData.maker;
		rackCode += "-"+rackData.model+rackData.height+rackData.width+rackData.depth+rackData.type;
		rackCode += "-"+rackData.color+"-"+rackData.row;
		
		if(rackCode.match(/^(YS|SO)-[BLSMH]\d{3}[SA]-[WG]-\d{2}$/)) {
			console.log(rackCode);
			return rackCode;
		} else {
			console.log(false);
			return false;
		}
	}

	// プレビュー表示
	function showPreview(rackCode) {
		if(rackCode) {
			$('.preview-caution').hide();
			rackDimPreviewBundle(rackCode, 'myCanvas');
		} else {
			$('.preview-caution').show();
		}
	}

	// Hによって選択可能な段数を制御
	function rowControl() {
		if(rackData.height == 1) {
			console.log(rackData.height);
			$('#search_step .row-9, #search_step .row-8').attr('disabled', true);
			$('#sp_search_step .row-9, #sp_search_step .row-8').attr('disabled', true);
		} else if (rackData.height == 7) {
			$('#search_step .row-9, #search_step .row-8, #search_step .row-7').attr('disabled', true);
			$('#sp_search_step .row-9, #sp_search_step .row-8, #sp_search_step .row-7').attr('disabled', true);
		} else {
			$('#search_step .row-9, #search_step .row-8, #search_step .row-7').attr('disabled', false);
			$('#sp_search_step .row-9, #sp_search_step .row-8, #sp_search_step .row-7').attr('disabled', false);
		}

		if($('#search_step option:selected').attr('disabled')) {
			$('#search_step').val('');	
			rackData.row = '';
			$('#s').val('');
		}

		if($('#sp_search_step option:selected').attr('disabled')) {
			$('#sp_search_step').val('');	
			rackData.row = '';
			$('#s').val('');
		}
	}

    /**
     * 品番確定時にラック検索実行
     * 
     * @param {string} rack_code
     * 
     * @return {void}
     */
    function searchRack(rack_code) {
		if (search_req) {
			search_req.abort();
		}

        search_req = $.get('/api/get-rack-data', {
            code: rack_code,
        }, function(res) {
            console.log(res);

            $('#rack-search__result').show();

			$('#rack-search__result--info-name').parent('a').attr('href', '/products/detail/' + res.product_id);
            $('#rack-search__result--info-name').text(res.name);
            $('#rack-search__result--info-code').text(res.code);
            $('#rack-search__result--info-price').text(parseInt(res.price02).toLocaleString());
            $('#rack-search__result--info-price-inc-tax').text(parseInt(res.price02_inc_tax).toLocaleString());

			$('#rack-search__result input[name=product_id]').val(res.product_id);
			$('#rack-search__result input[name=product_class_id]').val(res.product_class_id);
        })
    }

    /**
     * 品番取得、ラック検索、納期取得、寸法図プレビュー更新までの一括処理
     * 
     * @return {void}
     */
    function search() {
        // 品番取得
        var rackCode = rackCodeGenerate(rackData);

		// セッション変数にrackDataを保存
		sessionStorage.setItem('search_rack_data', JSON.stringify(rackData));

        // 品番確定判定
        if(rackCode) {
            $('#rack-search__caution').hide();

            // ラック検索実行
            searchRack(rackCode);
            
            $('#product-guide-stock').show();
            
            // 在庫・出荷日・到着日取得
            window.getStock(rackCode, $('#quantity').val(), $('#pref-list').val());
            
            // プレビュー更新
            showPreview(rackCode);
        } else {
			// 品番取得できなければ検索結果欄を非表示にして終了
            $('#rack-search__caution').show();
			$('#rack-search__result').hide();
			$('#product-guide-stock').hide();
			
			// プレビュー更新
            showPreview(rackCode);

			return false;
		}
    }

	// プルダウン表示制御
	function spSizeFilterUpdate() {
		// 全体の初期化
		$('#sp-filter-model option').attr('disabled', false);
		$('#sp-filter-height option').attr('disabled', false);
		$('#sp-filter-width option').attr('disabled', false);
		$('#sp-filter-depth option').attr('disabled', false);
		$('#sp-filter-row option').attr('disabled', false);
		$('#sp-filter-type option').attr('disabled', false);
		var model = parseInt($('#sp-filter-model option:selected').val());
	  
		// 耐荷重ボタンが押された場合のボタン制御
		switch(model) {
			case 120:
			  $('#filter-width-900').text('875mm');
			  $('#filter-width-1200').text('1200mm');
			  $('#filter-width-1500').text('1500mm');
			  $('#filter-width-1800').text('1800mm');
			  $('#filter-depth-700, #filter-depth-910').attr('disabled', true);
				break;
			case 150:
				$('#filter-width-900').text('900mm');
				$('#filter-width-1200').text('1200mm');
				$('#filter-width-1500').text('1500mm');
				$('#filter-width-1800').text('1800mm');
				$('#filter-depth-700, #filter-depth-910').attr('disabled', true);
				// $('#filter-width-900').attr('disabled', true);
				break;
			case 200:
				$('#filter-width-900').text('900mm');
				$('#filter-width-1200').text('1200mm');
				$('#filter-width-1500').text('1500mm');
				$('#filter-width-1800').text('1800mm');
				$('#filter-depth-700, #filter-depth-910').attr('disabled', true);
				break;
			case 300:
			case 500:
				$('#filter-width-900').text('950mm');
				$('#filter-width-1200').text('1250mm');
				$('#filter-width-1500').text('1550mm');
				$('#filter-width-1800').text('1850mm');
				$('#filter-depth-300').attr('disabled', true);
				break;
		}
		// 高さ
		if(model != 200) {
			$('#filter-height-700').attr('disabled', true);
			if(model == 150) {
				$('#filter-height-900').attr('disabled', true);
			}
		}
		// 形式
		switch(model) {
			case 120:
			case 150:
				$('#filter-type-connect').attr('disabled', true);
				break;
		}
	}

	function spSelectedControl(selectElem) {
		var flg = false;
		if(selectElem.find('option:selected').attr('disabled')) {
			$(this).attr('selected', false);
			selectElem.find('option').each(function(){
				if(!$(this).attr('disabled') && !flg) {
					$(this).attr('selected', 'selected');
					flg = true;
					$(this).attr('selected', 'selected').parent('select').addClass('active');
				} else {
					$(this).attr('selected', false);
				}
			});
			$('.display-sp select').on('change', function(){
			  $(this).removeClass('active');
			});
		}
	}

	// 選択しているモデルがdisabled状態にある場合、選択可能なモデルに切り替える
	function spSelectedControlAll() {
		$('.filter').each(function(){
			spSelectedControl($(this));
		});
	}
});